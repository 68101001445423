import React, { useEffect } from 'react';
import { HashRouter, Route, Redirect, Switch } from 'react-router-dom';

import App from '../views/App'

function Router() {
	useEffect(() => (window as any).L2Dwidget?.init({
		model: { jsonPath: '/live2dw/live2d-widget-model-koharu/assets/koharu.model.json' },
		display: { position: 'right', width: 100, height: 200 },
	}))

	return (
		<HashRouter>
			<Switch>
				<Route path="/" exact component={() => <App />} />
				<Redirect to="/" from='*' />
			</Switch>
		</HashRouter>
	)
}

export default Router;
