import { Colors } from "./color";
import DataBus from './databus';
import * as THREE from 'three';

const databus = new DataBus()
const game = databus.game


// 创建石头
class Ennemy {
	constructor() {
		this.mesh = null; // 实例
		this.angle = 0; // 当前位置（0~2π）
		this.distance = 0; // 距离圆心半径

		this.draw()
	}

	// 石头自转
	rotate() {
		this.angle += game.speed * databus.deltaTime * game.coinsSpeed;
		this.mesh.rotation.z += Math.random() * .1;
		this.mesh.rotation.y += Math.random() * .1;
		this.setPosition()
	}
	// 石头前进（位置更新）
	setPosition() {
		this.mesh.position.y = -game.seaRadius + Math.sin(this.angle) * this.distance;
		this.mesh.position.x = Math.cos(this.angle) * this.distance;
	}

	// 画石头实例
	draw() {
		// 创建一个(红色)(半径8)的球
		var geom = new THREE.TetrahedronGeometry(8, 2);
		var mat = new THREE.MeshPhongMaterial({
			color: Colors.red,
			shininess: 0,
			specular: 0xffffff,
			shading: THREE.FlatShading
		});
		this.mesh = new THREE.Mesh(geom, mat);
		this.mesh.castShadow = true;
	}

	update() {
		this.rotate();
		this.setPosition();
	}
}

// 石头列表
export default class EnnemyHolder {
	constructor(n = 10) {
		this.mesh = new THREE.Object3D();
		this.inUse = []; // 可用的石头
		this.pool = []; // 创建的所有石头

		for (let i = 0; i < n; i++) {
			let ennemy = new Ennemy();
			this.pool.push(ennemy);
		}
	}

	// 添加石头
	spawnEnnemy() {
		let ennemy = this.pool.length
			? this.pool.pop() // 储存池中有石头，就用储存池中的
			: new Ennemy(); // 新建石头实例

		ennemy.angle = 0.4 * Math.PI; // 设置石头初始出现位置
		// 石头高度（距离圆心长度）
		ennemy.distance = game.seaRadius + game.planeDefaultHeight + (-1 + Math.random() * 2) * (game.planeAmpHeight);
		ennemy.setPosition();

		this.mesh.add(ennemy.mesh); // 石头集实例中添加石头
		this.inUse.push(ennemy); // 使用中的石头添加
	}

	// 石头前进和自转
	update() {
		this.inUse.forEach(ennemy => {
			// 超过半圈的石头回收
			if (ennemy.angle > Math.PI) {
				this.pool.unshift(this.inUse.splice(this.inUse.indexOf(ennemy), 1)[0]);
				this.mesh.remove(ennemy.mesh);
			}
			ennemy.update()
		})
	}
}
