import { Colors } from "./color";
import DataBus from './databus';
import * as THREE from 'three';

const databus = new DataBus()
const game = databus.game


// 创建云
class Cloud {
	constructor() {
		this.mesh = null; // 实例

		this.draw()
	}

	// 云的旋转
	rotate() {
		var l = this.mesh.children.length;
		for (var i = 0; i < l; i++) {
			var m = this.mesh.children[i];
			m.rotation.z += Math.random() * .005 * (i + 1);
			m.rotation.y += Math.random() * .002 * (i + 1);
		}
	}

	draw() {
		this.mesh = new THREE.Object3D();
		this.mesh.name = "cloud";
		var geom = new THREE.CubeGeometry(20, 20, 20);
		var mat = new THREE.MeshPhongMaterial({
			color: Colors.white,
		});

		//* 一团云3-5个方块
		var nBlocs = 3 + Math.floor(Math.random() * 3);
		for (var i = 0; i < nBlocs; i++) {
			var m = new THREE.Mesh(geom.clone(), mat);
			m.position.x = i * 15; // 一块云x轴差15
			m.position.y = Math.random() * 10;
			m.position.z = Math.random() * 10;
			m.rotation.z = Math.random() * Math.PI * 2;
			m.rotation.y = Math.random() * Math.PI * 2;
			var s = .1 + Math.random() * .9; // 缩放 0.1-1
			m.scale.set(s, s, s);
			this.mesh.add(m);
			m.castShadow = true;
			m.receiveShadow = true;
		}
	}
}

// 创建天空
export default class Sky {
	constructor() {
		this.mesh = new THREE.Object3D(); // 实例
		this.nClouds = 20; // 20，云的数量
		this.clouds = []; // 所有云的实例集合

		this.draw()
		this.mesh.position.y = -game.seaRadius;
	}

	moveClouds(){
		for (var i = 0; i < this.nClouds; i++) {
			var c = this.clouds[i];
			c.rotate(); // 旋转
		}
		// 云向后移动
		this.mesh.rotation.z += game.speed * databus.deltaTime;
	}

	draw() {
		var stepAngle = Math.PI * 2 / this.nClouds;
		for (var i = 0; i < this.nClouds; i++) {
			var c = new Cloud();
			this.clouds.push(c);
			var a = stepAngle * i; // 0至π
			var h = game.seaRadius + 150 + Math.random() * 200;
			// 设置云的位置、角度、缩放
			c.mesh.position.y = Math.sin(a) * h;
			c.mesh.position.x = Math.cos(a) * h;
			c.mesh.position.z = -300 - Math.random() * 500;
			c.mesh.rotation.z = a + Math.PI / 2;
			var s = 1 + Math.random() * 2;
			c.mesh.scale.set(s, s, s);
			this.mesh.add(c.mesh); // 添加云
		}
	}
}