import { Colors } from "./color";
import DataBus from './databus';
import * as THREE from 'three';

const databus = new DataBus()
const game = databus.game

// 创建大海 
export default class Sea {
	constructor() {
		this.mesh = null; // 实例
		this.waves = []; // 波浪

		this.draw()
		this.mesh.position.y = -game.seaRadius;
	}

	// 海浪的动画
	moveWaves() {
		var verts = this.mesh.geometry.vertices;
		var l = verts.length; // 顶点数量
		for (var i = 0; i < l; i++) {
			var v = verts[i];
			var vprops = this.waves[i];
			// 波荡顶点的位置进行移动
			v.x = vprops.x + Math.cos(vprops.ang) * vprops.amp;
			v.y = vprops.y + Math.sin(vprops.ang) * vprops.amp;
			v.z = vprops.z + Math.sin(vprops.ang) * vprops.amp;
			vprops.ang += vprops.speed * databus.deltaTime; // 距离增加
			this.mesh.geometry.verticesNeedUpdate = true;
		}
	}

	draw() {
		var geom = new THREE.CylinderGeometry(game.seaRadius, game.seaRadius, game.seaLength, 200, 10);
		geom.applyMatrix(new THREE.Matrix4().makeRotationX(-Math.PI / 2));
		geom.mergeVertices();
		var l = geom.vertices.length; // 所有顶点数量

		for (var i = 0; i < l; i++) {
			var v = geom.vertices[i];
			//v.y = Math.random()*30;
			// 重设顶点位置，形成波浪
			this.waves.push({
				y: v.y,
				x: v.x,
				z: v.z,
				ang: Math.random() * Math.PI * 2, // 距离（初始为0-2π）
				amp: game.wavesMinAmp + Math.random() * (game.wavesMaxAmp - game.wavesMinAmp), // 顶点高度
				speed: game.wavesMinSpeed + Math.random() * (game.wavesMaxSpeed - game.wavesMinSpeed) // 浮动速度
			});
		};
		var mat = new THREE.MeshPhongMaterial({
			color: Colors.blue,
			transparent: true,
			opacity: .8,
			shading: THREE.FlatShading,
		});

		// 蓝色海洋实例
		this.mesh = new THREE.Mesh(geom, mat);
		this.mesh.name = "waves";
		this.mesh.receiveShadow = true;
	}

	update(){
		this.mesh.rotation.z += game.speed * databus.deltaTime;
		if (this.mesh.rotation.z > 2 * Math.PI) this.mesh.rotation.z -= 2 * Math.PI;
		this.moveWaves()
	}
}