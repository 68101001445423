import React, { useEffect } from 'react';
import '../../css/demo.css';
import '../../css/game.css';
import '../../css/styles.css';
// import { Link } from 'react-router-dom';

import Main from '../../js/main';

function App() {
	useEffect(() => { new Main() })
	return (
		<div className="game-holder" id="gameHolder">
			<div className="header">
				<h1><span>the</span>Aviator</h1>
				<h2>fly it to the end</h2>
				<div className="score" id="score">
					<div className="score__content" id="level">
						<div className="score__label">level</div>
						<div className="score__value score__value--level" id="levelValue">1</div>
						<svg className="level-circle" id="levelCircle" viewBox="0 0 200 200">
							<circle id="levelCircleBgr" r="80" cx="100" cy="100" fill="none" stroke="#d1b790" strokeWidth="24px" />
							<circle id="levelCircleStroke" r="80" cx="100" cy="100" fill="none" stroke="#68c3c0" strokeWidth="14px" strokeDasharray="502" />
						</svg>
					</div>
					<div className="score__content" id="dist">
						<div className="score__label">distance</div>
						<div className="score__value score__value--dist" id="distValue">000</div>
					</div>
					<div className="score__content" id="energy">
						<div className="score__label">energy</div>
						<div className="score__value score__value--energy" id="energyValue">
							<div className="energy-bar" id="energyBar"></div>
						</div>
					</div>
				</div>
			</div>
			<div className="world" id="world"></div>
			<div className="message message--replay" id="replayMessage">Click to Replay</div>
			<div className="message message--instructions" id="instructions">Grab the blue pills<span>avoid the red ones</span></div>
		</div>
	);
}

export default App;
