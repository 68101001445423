// 管控游戏状态

let instance

export default class DataBus {
	constructor() {
		if (instance)
			return instance

		instance = this

		this.reset()
	}

	// 初始化数据
	reset() {
		this.deltaTime = 0; // 一帧的时长
		this.oldTime = new Date().getTime(); // 上一帧的时间戳

		this.resetGame()
	}

	// 重新开始游戏，初始化所有状态
	resetGame() {
		this.game = {
			speed: 0, // 当前速度
			initSpeed: .00007, // 初始速度
			baseSpeed: .00007, // 当前等级的基础速度
			targetBaseSpeed: .00007, // 当前等级的速度
			incrementSpeedByTime: .000005, // 每（100）距离增加的速度
			distanceForSpeedUpdate: 100, // 每（100）距离增加速度
			speedLastUpdate: 0, // 上次增加速度的距离

			distance: 0, // 距离
			ratioSpeedDistance: 50, // 每帧增加距离的基数
			energy: 100, // 血量
			ratioSpeedEnergy: 3, // 每帧扣血的基础

			level: 1, // 等级
			incrementSpeedByLevel: .00005, // 每级增加的速度
			levelLastUpdate: 0, // 上次升级的位置
			distanceForLevelUpdate: 1000, // 每（1000）距离升级

			planeDefaultHeight: 100, // 飞机飞行默认高度
			planeAmpHeight: 80, // 飞机（最高/最低）高度和默认高度差值 （20~80）
			planeAmpWidth: 75, // 飞机前后位置
			planeMoveSensivity: 0.005, // 飞机移动速度的基数
			planeRotXSensivity: 0.0008, // 飞机移动时X轴旋转的基数
			planeRotZSensivity: 0.0004, // 飞机移动时Z轴旋转的基数
			planeFallSpeed: .001, // 飞机坠落速度
			planeMinSpeed: 1.2, // 飞机最小速度
			planeMaxSpeed: 1.6, // 飞机最大速度
			planeSpeed: 0, // 飞机速度
			planeCollisionDisplacementX: 0, // 碰撞后的位置差
			planeCollisionSpeedX: 0, // 碰撞后的速度
			planeCollisionDisplacementY: 0, // 碰撞后的位置差
			planeCollisionSpeedY: 0, // 碰撞后的速度

			seaRadius: 3000, // 海洋圆柱半径（地球半径）
			seaLength: 800, // 海洋圆柱高度（海洋宽度）
			//seaRotationSpeed:0.006,
			wavesMinAmp: 5, // 波浪最低高度
			wavesMaxAmp: 20, // 波浪最高高度
			wavesMinSpeed: 0.001, // 波浪最低浮动速度
			wavesMaxSpeed: 0.003, // 波浪最高浮动速度

			// cameraFarPos: 500,
			// cameraNearPos: 150,
			cameraSensivity: 0.002, // 摄像机上下偏移基数

			coinDistanceTolerance: 15, // 硬币碰撞差
			coinValue: 3, // 每个硬币恢复（3）滴血
			coinsSpeed: .5, // 硬币的速度（前进速度的.5倍）
			coinLastSpawn: 0, // 上次创建硬币集的距离
			distanceForCoinsSpawn: 100, // 每（100）距离创建一个硬币集

			ennemyDistanceTolerance: 10, // 石头碰撞差
			ennemyValue: 10, // 每个石头（10）滴血
			ennemiesSpeed: .6, // 石头的速度（前进速度的.6倍）
			ennemyLastSpawn: 0, // 上次创建石头集的距离
			distanceForEnnemySpawn: 10,// 每（50）距离创建一个石头

			status: "playing", // 游戏状态
		};
	}
}
