import * as THREE from 'three';
import {TweenMax} from "gsap";

const particlesPool = [];

// 创建破碎特效碎片
class Particle {
	constructor() {
		this.mesh = null; // 实例

		this.draw()
	}

	explode(pos, color, scale) {
		const _this = this;
		var _p = this.mesh.parent;
		this.mesh.material.color = new THREE.Color(color);
		this.mesh.material.needsUpdate = true;
		this.mesh.scale.set(scale, scale, scale);
		var targetX = pos.x + (-1 + Math.random() * 2) * 50;
		var targetY = pos.y + (-1 + Math.random() * 2) * 50;
		var speed = .6 + Math.random() * .2;
		TweenMax.to(this.mesh.rotation, speed, { x: Math.random() * 12, y: Math.random() * 12 });
		TweenMax.to(this.mesh.scale, speed, { x: .1, y: .1, z: .1 });
		TweenMax.to(this.mesh.position, speed, {
			x: targetX, y: targetY, delay: Math.random() * .1, ease: '', onComplete: function () {
				if (_p) _p.remove(_this.mesh);
				_this.mesh.scale.set(1, 1, 1);
				particlesPool.unshift(_this);
			}
		});
	}

	draw() {
		var geom = new THREE.TetrahedronGeometry(3, 0);
		var mat = new THREE.MeshPhongMaterial({
			color: 0x009999,
			shininess: 0,
			specular: 0xffffff,
			shading: THREE.FlatShading
		});
		this.mesh = new THREE.Mesh(geom, mat);
	}
}

// 破碎特效（吃硬币或撞石头）
export default class ParticlesHolder {
	constructor() {
		this.mesh = new THREE.Object3D(); // 实例
		this.particlesInUse = [];

		for (var i = 0; i < 10; i++) {
			var particle = new Particle();
			particlesPool.push(particle);
		}
	}

	// 添加特效
	spawnParticles(pos, density, color, scale) {
		var nPArticles = density;
		for (var i = 0; i < nPArticles; i++) {
			var particle;
			if (particlesPool.length) {
				particle = particlesPool.pop();
			} else {
				particle = new Particle();
			}
			this.mesh.add(particle.mesh);
			particle.mesh.visible = true;
			particle.mesh.position.y = pos.y;
			particle.mesh.position.x = pos.x;
			particle.explode(pos, color, scale);
		}
	}
}