import React from 'react';
import ReactDOM from 'react-dom';
import * as serviceWorker from './serviceWorker';

import './index.css';
import './css/style.css';

import Router from './router'

ReactDOM.render(<Router />, document.getElementById('root'))

serviceWorker.unregister();
